import React from 'react'
// Page types
import PageHome from './Pages/PageHome/PageHome'
import PageCampaign from './Pages/PageCampaign/PageCampaign'

// Global Modules
import Header from './GlobalComponents/Header/Header'
import Footer from './GlobalComponents/Footer/Footer'

// Modules
import FAQ from './Modules/FAQ/FAQ'
import Newsletter from './Modules/Newsletter/Newsletter'
import Tour from './Modules/Tour/Tour'
import Releases from './Modules/Releases/Releases'
import Merchandise from './Modules/Merchandise/Merchandise'

// Components
import ComponentNotFound from './ComponentNotFound'
import StoryblokImage from './Components/StoryblokImage/StoryblokImage'
import PageSingleLaunch from './Pages/PageSingleLaunch/PageSingleLaunch'
import PageTour from './Pages/PageTour/PageTour'
import TourDate from './Components/TourDate/TourDate'

// Modules
const ComponentList: any = {
  // Page types
  page: PageHome,
  page_campaign: PageCampaign,
  page_single_launch: PageSingleLaunch,
  page_tour: PageTour,

  // Global Components
  header: Header,
  footer: Footer,

  // Modules
  faq: FAQ,
  newsletter: Newsletter,
  tour: Tour,
  releases: Releases,
  merchandise: Merchandise,

  // Components
  image: StoryblokImage,
  tour_date: TourDate,
}

const Components = (type: string) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components
