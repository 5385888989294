// extracted by mini-css-extract-plugin
export var bg = "NewsletterForm-module--bg--+29NL";
export var bottom = "NewsletterForm-module--bottom--V9HlQ";
export var button = "NewsletterForm-module--button--mlOnL";
export var close = "NewsletterForm-module--close--Cwb52";
export var fadeIn = "NewsletterForm-module--fadeIn--Lkf0q";
export var heading = "NewsletterForm-module--heading--Vq+ja";
export var inner = "NewsletterForm-module--inner--wkGjM";
export var input = "NewsletterForm-module--input--TV1Ms";
export var invalid = "NewsletterForm-module--invalid--9Ff3l";
export var message = "NewsletterForm-module--message--ar5KY";
export var status = "NewsletterForm-module--status--PXwz5";
export var top = "NewsletterForm-module--top--oxjo3";
export var wrapper = "NewsletterForm-module--wrapper--0SWq-";